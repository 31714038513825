// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Titillium Web', sans-serif !important;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$light-gray: #d3d3d3;
$dark-gray: #404040;
$blue: #0071bc;
$dark-blue: #004369;
$navy: #001f3f;
$indigo: #6574cd;
$purple: #6f42c1;
$pink: #f66d9b;
$red: #c7282d;
// $orange: #f6993f;
$orange: #FF6501;
// $yellow: #ffed4a;
$yellow: #f5ae0a;
$green: #008500;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$maroon: #d81b60;
$olive: #3d9970;
$gold: #d6b86e;

$brand-orange: #FF6501;
$brand-blue: #1A2C90;


$alert-help: #3592ff;
$alert-warning: #f6993f;
$alert-info: #3592ff;
$alert-error: #b51700;
$alert-success: #42ae42;
