/*** ALERT ***/
.alert { 
    position: relative; padding: 0; min-width: 250px;

    a { color: #2d3a51; }

    .alert-link { font-weight: normal; color: #4790e6; }

    ul {
        margin: 0;

        li { 
            margin-bottom: 0.2rem;
            &:last-child { margin-bottom: 0; }
        }
    }

    .alert-buttons {
        margin: 0.8rem 0.7rem;
        padding: 0;
        background-color: transparent;
        border: 0;
        opacity: 0.75;
        line-height: initial;
        font-size: 1.1rem;
        font-weight: 700;

        &:hover { opacity: 1; }
    }

    &.alert-warning .alert-buttons {
        color: $alert-warning;
    }
    &.alert-danger .alert-buttons {
        color: $alert-error;
    }
    &.alert-info .alert-buttons {
        color: $alert-info;
    }
}

.alert-icon, .alert-text { display: table-cell; vertical-align: middle; }

.alert-icon {
    min-width: 40px;
    text-align: center;
    font-size: 1.5rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 0.6rem 0.9rem;
}

.alert-text { color: #2d3a51; padding: 0.6rem 1.3rem; width: 100%; }

.alert-text > h5 { margin-top: 0; font-weight: 600; }

.alert-warning {
    background-color: lighten($alert-warning, 34%);
    border-color: $alert-warning; //lighten($alert-warning, 35%);
    // border: 0;

    .alert-text h5 { color: $alert-warning; }

    button.close { color: $alert-warning; }

    .alert-icon { background-color: $alert-warning; }
}

.alert-help {
    background-color: lighten($alert-help, 48%);
    border-color: $alert-help; //lighten($alert-help, 34%);
    // border: 0;

    .alert-text h5 { color: $alert-help; }

    button.close { color: $alert-help; }

    .alert-icon { background-color: $alert-help; }
}

.alert-info {
    background-color: lighten($alert-info, 36%);
    border-color: $alert-info; //lighten($alert-info, 30%);
    // border: 0;

    .alert-text h5 { color: $alert-info; }

    button.close { color: $alert-info; }

    .alert-icon { background-color: $alert-info; }
}

.alert-danger {
    background-color: lighten($alert-error, 61%);
    border-color: $alert-error; //lighten($alert-error, 38%);
    // border: 0;

    .alert-text h5 { color: $alert-error; }

    button.close { color: $alert-error; }

    .alert-icon { background-color: $alert-error; }
}

.alert-success {
    background-color: lighten($alert-success, 48%);
    border-color: $alert-success; //lighten($alert-success, 34%);
    // border: 0;

    .alert-text h5 { color: $alert-success; }

    button.close { color: $alert-success; }

    .alert-icon { background-color: $alert-success; }
}

/*** SWEET ALERT 2 ***/
.swal2-popup.swal2-toast .swal2-title { margin: 0 0 0 5px; }
.swal2-popup.swal2-toast.swal2-icon-error { background: #ffe4e4; }
.swal2-popup.swal2-toast.swal2-icon-warning { background: #fff3e8; }

.swal2-actions button { margin: 0 5px; }

.swal2-styled { padding: 0.375rem 0.75rem; font-size: 1rem; }