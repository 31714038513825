// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');
// @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

// Variables
@import 'variables';

// Dropdown
// @import 'dropdown';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


p { margin-bottom: 0.5rem; }

.text-decoration-line-through { text-decoration: line-through; }

/*** FONT COLORS ***/

.text-dark { color: $dark-gray !important; }

/*** BACKGROUNDS & COLORS ***/
.wrapper { background-color: #f9f9f9; }
.content-wrapper { background-color: inherit; }

.bg-primary, .bg-primary > a { color: #fff !important; }
.bg-warning, .bg-warning > a { color: #7b5705 !important; }
.bg-info, .bg-info > a { color: $dark-blue !important; }
.bg-yellow, .bg-yellow > a { color: $dark-gray !important; }
.bg-dark, .bg-dark > a { background-color: $dark-gray !important; }
.bg-orange, .bg-orange > a { background-color: $orange !important; color: #fff !important; }

.bg-brand-blue, .bg-brand-blue > a { background-color: $brand-blue !important; color: #fff !important; }

.btn-link { color: $orange; }

@media print {
    body {
        background-color: white;
    }
}

/*** ICONS ***/
[class*=icon-icon-] { display: inline-block; position: relative; top: 2px; }

/*** NAVBAR ***/
// .brand-link .brand-image { margin-left: .5rem; }

// .navbar-badge { top: 2px; right: 0px;  font-size: .6rem !important; }
.navbar-badge { font-size: .6rem; font-weight: 300; padding: 2px 4px; position: absolute; right: 3px; top: 3px; }

.navbar-expand .navbar-nav .nav-link { display: block; padding: 0.5rem 1rem; }

.dropdown-menu-lg {
    max-width: 300px;
    min-width: 280px;
    padding: 0;

    .dropdown-divider {
        margin: 0;
    }

    .dropdown-item {
        padding: $dropdown-padding-y $dropdown-item-padding-x;
    }

    p {
        margin: 0;
        white-space: normal;
    }
}


.langs-menu {
    img { width: 18px; }
}


/*** MAIN SIDEBAR ***/
[class*=sidebar-dark-] { 
    background-color: $dark-gray;

    .sidebar a { color: #e4e4e4; }

    .brand-text { font-size: 16px; }

    .nav-sidebar>.nav-item.menu-open>.nav-link,
    .nav-sidebar>.nav-item:hover>.nav-link,
    .nav-sidebar>.nav-item>.nav-link:focus,
    .nav-sidebar>.nav-item>.nav-link.active {
        background-color: $orange;
        color: #fff;
    }

    .nav-sidebar .divider { border-top: 1px solid $light-gray; margin: 6px 0; }

    ul.nav-treeview { border-left: 4px solid #5c5c5c; }
}

.user-panel .info small { color: #fff; }

/*** CONTROL SIDEBAR ***/

.control-sidebar, .control-sidebar::before { width: 500px; right: 0px; }

/*** CONTENT ***/

.content-wrapper .container { transition: all 1s; }

/*** CARD ***/
.card-primary.card-outline { border-top: 3px solid $blue; }
.card-primary:not(.card-outline) > .card-header {
    background-color: $blue;

    a.active { border-top: 3px solid $blue; }
}
.card-primary.card-outline-tabs > .card-header a.active { border-top: 3px solid $blue; }

.card-danger.card-outline { border-top: 3px solid $red; }
.card-danger:not(.card-outline) > .card-header { 
    background-color: $red;

    a.active { border-top: 3px solid $red; }
}
.card-danger.card-outline-tabs > .card-header a.active { border-top: 3px solid $red; }

.card-dark.card-outline { border-top: 3px solid $dark-gray; }
.card-dark:not(.card-outline) > .card-header {
    background-color: $dark-gray;

    a.active { border-top: 3px solid $dark-gray; }
}
.card-dark.card-outline-tabs > .card-header a.active { border-top: 3px solid $blue; }

.card-lightgray.card-outline { border-top: 3px solid $light-gray; }
.card-lightgray:not(.card-outline) > .card-header {
    background-color: $light-gray;

    a.active { border-top: 3px solid $light-gray; }
}
.card-lightgray.card-outline-tabs > .card-header a.active { border-top: 3px solid $blue; }

.card-header .badge { position: relative; top: -1px; }

.card-header .right-buttons {
    float: right; position: relative; top: -4px;
}

/*** NAV TABS ***/
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active { background-color: #fff; border-bottom-color: white; }

/*** MULTILANG ***/
.multilang-label {
    /*** LABEL FORM ***/
    line-height: 1.2;

    small { display: block; font-size: 11px; }
    sup { 
        position: absolute; top: 10px;

        a.help { position: relative; top: -6px; }
    }

    /*** HEADER ***/
    .card-tools { position: absolute; top: 10px; right: 20px; }

    /*** INFO BOX ***/
    .info-box-text { line-height: 1.3; }
}

/*** FIELDSET ***/

.fieldset-title {
    margin-bottom: 23px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eaeaf1;
    color: #616670;
    font-size: 1.3rem;
}

/*** INPUT ***/

label:not(.form-check-label):not(.custom-file-label) { font-weight: 600; }

.form-check-label { margin-left: 4px; }

.custom-select.form-control-border, .form-control.form-control-border { box-shadow: inherit; border-left: 1px solid #ced4da; }

.form-control-plaintext {
    padding: 0.397rem 0.75rem; border: 1px solid #ced4da; border-radius: 0.25rem; background-color: #f7f7f7;

    &:focus-visible { outline: none; }

    &.form-control-border { border-top: 0; /*border-left: 0;*/ border-right: 0; border-radius: 0; }
}

select[readonly] { pointer-events: none; touch-action: none; }

.form-control:focus { border-color: $blue; }

.input-group-prepend {
    & > .input-group-text { padding: 0.375rem 0.5rem; }
}
.input-group-append {
    & > .input-group-text { padding: 0.375rem 0.5rem; }

    &, .btn + .btn { margin-left: 0; }
}

.input-group > .select2-container--bootstrap4 {
    width: auto !important;
}
.input-group > .select2-container--bootstrap4 .select2-selection--single {
    height: 100% !important;
}

/*** SELECT2 ***/

.select2-results__group { color: $blue !important; }

.select2-results__option {
    border-bottom: solid 1px #ddd;

    .select2-result-repository__details { font-size: 12px; }
}
.select2-results__options::-webkit-scrollbar {
    width: 5px;
    background-clip: padding-box;
}
.select2-results__options::-webkit-scrollbar-track {
    background-color: #e7e7e7;
    height: 8px;
    background-clip: padding-box;
    // border-right: 10px solid rgba(0, 0, 0, 0);
    // border-top: 10px solid rgba(0, 0, 0, 0);
    // border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.select2-results__options::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: $blue;
    // border-right: 10px solid rgba(0, 0, 0, 0);
    // border-top: 10px solid rgba(0, 0, 0, 0);
    // border-bottom: 10px solid rgba(0, 0, 0, 0);
}
.select2-results__options::-webkit-scrollbar-button {
    display: none;
}

.select2-result-repository__avatar { 
    float: left; width: 60px; margin-right: 10px;

    .placeholder-img { width: 50px; }
}

.select2-container--bootstrap4 .select2-selection--single {
    height: calc(1.5em + 0.75rem + 4px) !important;
}

.select2-selection--single {
    border-top: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    
    .select2-result-repository__details { font-size: 12px; }
}

// .select2-container--default .select2-selection--single, .select2-container--bootstrap4 .select2-selection--single { height: auto !important; }

.select2-selection--multiple {
    border-top: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;

    .select2-selection__choice { color: #555 !important; }
    .select2-selection__choice__remove { color: #888 !important; }
    .select2-result-repository__title { font-size: 1rem; }
    .select2-result-repository__meta { padding: 0 15px 0 5px; }
    .select2-result-repository__details { font-size: 12px; }
}

select[readonly].select2-hidden-accessible + .select2-container { pointer-events: none; touch-action: none; }
select[readonly].select2-hidden-accessible + .select2-container .select2-selection { background: #eee; box-shadow: none; }
select[readonly].select2-hidden-accessible + .select2-container .select2-selection__arrow,
select[readonly].select2-hidden-accessible + .select2-container .select2-selection__clear,
select[readonly].select2-hidden-accessible + .select2-container .select2-search { display: none; }

.select2-results__option--highlighted { color: inherit !important; background-color: #edf5ff !important; }

.select2-results__option.disabled { background-color: #dedede; cursor: not-allowed; }

.select2-rendered__match { color: $blue; font-weight: bold; }

/*** BUTTON ***/
.btn-xs { padding: .125rem .25rem; font-size: .75rem; line-height: 1.5; border-radius: .15rem; }

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active { color: #fff; }

.btn-outline-darkblue { color: $dark-blue; border-color: $dark-blue; }
.btn-outline-darkblue:not(:disabled):not(.disabled).active,
.btn-outline-darkblue:hover {
    color: #fff; background-color: $dark-blue; border-color: $dark-blue;
}

.btn-outline-darkgray { color: $dark-gray; border-color: $dark-gray; }
.btn-outline-darkgray:not(:disabled):not(.disabled).active,
.btn-outline-darkgray:hover {
    color: #fff; background-color: $dark-gray; border-color: $dark-gray;
}

.btn-outline-purple { color: $purple; border-color: $purple; }
.btn-outline-purple:not(:disabled):not(.disabled).active,
.btn-outline-purple:hover {
    color: #fff; background-color: $purple; border-color: $purple;
}

.btn-outline-navy { color: $navy; border-color: $navy; }
.btn-outline-navy:not(:disabled):not(.disabled).active,
.btn-outline-navy:hover {
    color: #fff; background-color: $navy; border-color: $navy;
}

.btn-outline-teal { color: $teal; border-color: $teal; }
.btn-outline-teal:not(:disabled):not(.disabled).active,
.btn-outline-teal:hover {
    color: #fff; background-color: $teal; border-color: $teal;
}

.btn-outline-maroon { color: $maroon; border-color: $maroon; }
.btn-outline-maroon:not(:disabled):not(.disabled).active,
.btn-outline-maroon:hover {
    color: #fff; background-color: $maroon; border-color: $maroon;
}

.btn-outline-olive { color: $olive; border-color: $olive; }
.btn-outline-olive:not(:disabled):not(.disabled).active,
.btn-outline-olive:hover {
    color: #fff; background-color: $olive; border-color: $olive;
}

/*** INVALID INPUT ***/
.group-invalid ~ .invalid-feedback { display: block; }

/*** CHECKED ICON ***/
.checked-icon { position: relative; top: 3px; font-size: 21px; }
.unchecked-icon { position: relative; top: 3px; font-size: 21px; color: #d7dcde; }

/*** iCheck ***/
.icheckbox_flat-blue { top: -2px; }

/*** TABLE ***/
/*th { text-align: center !important; }*/
td { vertical-align: middle !important; }

td.small-text { font-size: 0.8rem; }

.table-hover>tbody>tr:hover>td { background-color: #f0f7fd !important; }

td .btn { margin: 1px; }
td .action-buttons.btn-xs { width: 25px; }
td .action-buttons.btn-sm { width: 32px; }

td h5 { margin-bottom: 0.25rem; }

td .placeholder-img { width: 50px; }

/*** DATATABLES ***/
.dt-buttons button:not(.btn-sm) { position: relative; top: -7px; }

/*** BADGE ***/
.badge {
    font-weight: 600;
    // padding-top: 0.25rem;

    &.badge-info { color: $dark-blue; }

    &.disabled { opacity: 0.5; text-decoration: line-through; text-decoration-color: #555; }
}

p > .badge { font-size: 0.8rem; }

.badge-light { background-color: #e9ecef; }
.badge-darkblue { color: #fff; background-color: $dark-blue; }
.badge-darkgray { color: #fff; background-color: $dark-gray; }
.badge-purple { color: #fff; background-color: $purple; }
.badge-navy { color: #fff; background-color: $navy; }
.badge-teal { color: #fff; background-color: $teal; }
.badge-maroon { color: #fff; background-color: $maroon; }
.badge-olive { color: #fff; background-color: $olive; }
.badge-pink { color: #fff; background-color: $pink; }

.col-form-label .badge { font-size: 100%; }

/*** CALLOUT ***/
.callout { border-left: 8px solid #e9ecef; }
.callout.callout-info { border-left-color: $cyan; }
.callout.callout-warning { border-left-color: $orange; }

/*** RIBBON ***/
.ribbon-wrapper .ribbon { font-size: .75rem; text-shadow: 0 0 black; }

/*** FIELDSET ***/
fieldset .right-buttons {
    float: right;
    position: relative;
    top: -4px;
}

/*** FIELDSET SECTION WITH BORDER ***/
.fieldset-section-border {
    padding: 0 10px;
    margin-bottom: 15px;
    border-radius: 4px;

    &.fieldset-section-border-full {
        border: 1px solid #c5c5c5;
    }
    &.fieldset-section-border-top {
        border-top: 1px solid #c5c5c5;
    }

    legend {
        width: auto;
        padding: 0.2em 0.5em;
        color: #b1b1b1;
        font-size: 95%;
        position: relative;
        margin-left: auto;
        margin-right: auto;

        &:before {
            position: absolute;
            content: '';
            height: 11px;
            border-left: 1px solid #c5c5c5;
            left: 0px;
            top: 8px;
        }

        &:after {
            position: absolute;
            content: '';
            height: 11px;
            border-right: 1px solid #c5c5c5;
            right: 0px;
            top: 8px;
        }
    }
}

/*** SMALL BOX ***/
.small-box a { 
    // color: #ffffff; text-decoration: none;

    &:hover { text-decoration: underline; }

    // &.btn-success { color: #ffffff !important; }
}

/*** INFO BOX ***/
.info-box {
    .info-box-content { padding: 0 10px; }

    .info-box-text, .progress-description { white-space: normal; height: 30px; }
    .info-box-text { 
        font-size: 0.95rem;

        h5 { margin-bottom: 0; }
    }

    .description-percentage { line-height: 1.3rem; }
}

/*** TOOLTIP ***/
.tooltip-inner { background-color: $blue; }
.tooltip.bs-tooltip-right .arrow:before { border-right-color: $blue !important; }
.tooltip.bs-tooltip-left .arrow:before { border-left-color: $blue !important; }
.tooltip.bs-tooltip-bottom .arrow:before { border-bottom-color: $blue !important; }
.tooltip.bs-tooltip-top .arrow:before { border-top-color: $blue !important; }

/*** POPOVER ***/
.popover-item-container { 
    min-width: 220px;
    max-height: 80vh;
    overflow-y: scroll;

    .badge { font-size: 0.8rem; }
}

#popover-product-img-container {
    min-height: 100px;

    img { height: 100px; }
}

/*** HELP ***/
i[data-toggle="tooltip"], .badge[data-toggle="tooltip"] { cursor: help; }

/*** CHECK LIST ***/
ul.check-list { 
    padding-left: 0;
    
    li {
        position: relative;
        list-style-type: none;
        padding-left: 1.2rem;
        margin-bottom: 0.2rem;
    }
    li:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 5px;
        height: 11px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        border-color: #00a8a8;
        transform-origin: bottom left;
        transform: rotate(45deg);
    }
}

/*** CHECKBOX LIST ***/
.checkbox-list-columns { 
    column-count: 2; column-gap: 2rem; list-style: none; padding-left: 0;

    .icheckbox_flat-blue, .checked-icon, .unchecked-icon {
        position: absolute !important;
        top: 4px;
        left: 0;
    }

    .container {
        position: relative;
        padding-top: 3px;
        padding-left: 35px;
        font-weight: normal !important;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    @media only screen and (max-width: 414px) {
        column-count: 1;
    }
}

/*** NOT EDITABLE BLOCK ***/
.not-editable-block { padding: 1rem; margin-bottom: 1rem; border: 2px dashed #dee2e6; }


/*** LEFT SUMMARY BOX ***/
.left-summary-box {
    .badge { font-size: 0.8rem; }
}

/*** PROFILE PAGE ***/
.profile-page {
    .box-profile {
        .badge { font-size: 0.8rem; }
    
        .social-icons a { font-size: 1.4rem; padding: 3px; }
    }

    .fieldset-title {
        margin-bottom: 23px;
        padding-bottom: 5px;
        border-bottom: 1px solid #eaeaf1;
        color: #616670;
        font-size: 1.3rem;
    }
    
    @media (min-width: 1281px) {
        .col-resume { max-width: 285px; padding-right: 0; }
    }
}

/*** TIMELINE ***/
.timeline-item {
    .badge-pill { position: relative; top: -2px; padding-top: 5px; }

    .ribbon-wrapper .ribbon { top: 23px !important; }

    .timeline-item-left { 
        flex: 0 0 13%; max-width: 13%;

        @media (max-width: 468px) {
            flex: 0 0 100%; max-width: 100%;
        }
        @media (min-width: 469px) and (max-width: 768px) {
            flex: 0 0 30%; max-width: 30%;
        }
        @media (min-width: 769px) and (max-width: 1024px) {
            flex: 0 0 20%; max-width: 20%;
        }
    }
    .timeline-item-right {
        flex: 0 0 87%; max-width: 87%;

        @media (max-width: 468px) {
            flex: 0 0 100%; max-width: 100%;
        }
        @media (min-width: 469px) and (max-width: 768px) {
            flex: 0 0 70%; max-width: 70%;
        }
        @media (min-width: 769px) and (max-width: 1024px) {
            flex: 0 0 80%; max-width: 80%;
        }
    }
}

/*** TOTALS ***/
#totals-container dt sup { position: absolute; top: 6px; right: 1px; }

/*** SYNC MESSAGES PANEL ***/
#sync_messages_panel .card-body { max-height: 380px; overflow-y: auto; }

/*** DOCUMENTS ***/
#documents-container {
    .doc-item:not(:last-child) { border-bottom: 1px solid #e5e5e5; }
    .doc-item p { font-size: 0.7rem; }

    .doc-item-icon {
        font-size: 30px;
        /*background: #e9ecef;*/
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        /*border-radius: 50%;*/
    }

    .ms-2 { margin-left: .5rem!important; }
}

#add-file-container {
    .btn-upload { margin-left: 10px; }
    .upload-input-group { margin-bottom: 10px; }

    .input-group > .custom-select, .input-group > .form-control { padding: 3px 7px; }
}

/*** ERROR PAGE ***/
.error-page .headline i { font-size: 4rem; }

.error-page > .error-content { margin-left: 160px; }


/*** X-EDITABLE ****/
.editable-clear-x { right: 7px !important; }


/*** COOKIEBOT ****/
.CookiebotWidget-logo { position: relative; bottom: 0px; }


/*** FLAGS ***/
.tab-flag { width: 18px; }



/*** ACCESSIBILITY ***/
#pojo-a11y-toolbar {
    top: 60px !important;

    .pojo-a11y-toolbar-toggle a {
        background-color: #1A2C90;
        color: #ffffff;
    }
}

.pojo-a11y-toolbar-overlay {
    background-color: #ffffff;
}

// Custom components
@import 'alert';
